import React, {useEffect} from "react";
import {Container} from "react-bootstrap";
import {Helmet, HelmetProvider} from "react-helmet-async";

export default function Home() {

    return (
        <HelmetProvider>
            <Helmet>
                <title>Areeb Majeed</title>
                <meta name="description" content="Areeb Majeed is a backend developer and entrepreneur based in Australia. He specializes in PHP, Golang, and MySQL."/>
            </Helmet>
            <Container className={"container-small"}>
                <div className={"text-justify"}>

                    <h1 className={"h1 pb-4"}>
                        hi there, how you doing? 🖐️
                    </h1>

                    <p>
                        I'm Areeb Majeed. Welcome to my site.
                    </p>

                    <p>
                        As a backend developer and co-founder of <a href={"https://maileroo.com/"} target={"_blank"} className={"special-item"}><img src={"assets/images/maileroo.webp"} className={"mr-2"} alt={"Maileroo Logo"}/> <span>Maileroo</span></a>, I bring a rich experience of eight years specializing in PHP, Golang, and MySQL. I also possess basic to medium-level skills in JavaScript, Java, Python and C# as I studied them during my time at <a href={"https://www.rmit.edu.au/"} target={"_blank"} className={"special-item"}><img src={"assets/images/rmit.png"} className={"mr-2"} alt={"RMIT University Logo"}/> <span>RMIT University</span></a>.
                    </p>

                    <p>
                        At work, I often use tools like Ansible, Terraform, Redis, RabbitMQ, Laravel, and React and I'm really interested in topics like DevOps, cryptocurrencies, and cloud computing.
                    </p>

                    <p>
                        Right now, I'm really into the tech startup scene. When I'm not working, I love to play video games and tinker with my own side projects.
                    </p>

                    <p>
                        I am currently based in Australia.
                    </p>

                    <br/>

                    <p>
                        <a href={"/guestbook"} className={"link"}><i data-feather="arrow-up-right" className={"sm-icon"}></i> Say hello!</a>
                    </p>

                </div>
            </Container>
        </HelmetProvider>
    );

}